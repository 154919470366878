<template>
  <div class="useral-insert-court">
    <div class="view-side mt-3 sm:mt-0">
      <div class="view-box">
        <vs-row>
          <vs-col>

            <div>
              <div class="select-user-invoice logo-input">
                <custom-file-input :label="$t('courts.labels.image')"
                                   :is-empty="true"
                                   :classes="{'preview-image': true}"
                                   logo-type="scoreCategory"
                                   :styles="{width: '100%'}"
                                   v-model="court.logo"
                                   :disabled="false"/>

              </div>

              <custom-validate-input :label="$t('courts.labels.name')"
                                     :classes="{'w-full': true}"
                                     align="right"
                                     rtl
                                     v-model="court.name"/>

              <div class="input-field-item" :class="court.type.id === -1 ? 'is-invalid' : ''">
                <label class="input-field-label">{{ $t('courts.labels.type') }}</label>
                <div v-for="(item, key) in courtTypes" class="radio-item">
                  <label :for="`courtTypeRadio${key}`">{{ item.label }}</label>
                  <input :id="`courtTypeRadio${key}`" type="radio" :key="key" name="courtType" :value="item.id"
                         v-model="court.type.id"/>
                </div>
              </div>

              <!--                <div class="input-field-item" :class="!court.roof.id ? '' : ''">-->
              <!--                    <label class="input-field-label">سرپوشیده</label>-->
              <!--                    <div v-for="(item, key) in roofTypes" class="radio-item">-->
              <!--                        <label :for="`roofTypeRadio${key}`">{{ item.label }}</label>-->
              <!--                        <input :id="`roofTypeRadio${key}`" type="radio" :key="key" name="roofType" :value="item.id"-->
              <!--                               v-model="court.roof.id"/>-->
              <!--                    </div>-->
              <!--                </div>-->
            </div>
          </vs-col>
        </vs-row>
      </div>
    </div>

    <vs-button v-show="false"
               id="insertCourtBTN"
               @click="sendData"/>
  </div>
</template>

<script>
  import axios from 'axios'
  import CustomValidateInput from '../../../../components/customInput/customValidateInput'
  import CustomFileInput from "../../../../components/customInput/customeFileInput";
  import {insertCourt} from '../../../../http/requests/courts'
  import {checkUserPermissions} from '../../../../assets/js/functions'

  export default {
    name: 'InsertCourt',
    components: {
      CustomFileInput,
      CustomValidateInput
    },
    props: {
      courtId: 0
    },
    data() {
      return {
        courtTypes: [
          {
            id: 2,
            label: this.$t('courts.types.ground'),
            value: 2
          },
          {
            id: 1,
            label: this.$t('courts.types.hard'),
            value: 1
          },
          {
            id: 3,
            label: this.$t('courts.types.grass'),
            value: 3
          }
        ],
        roofTypes: [
          {
            id: 1,
            label: this.$t('courts.roofTypes.always'),
            value: 1
          },
          {
            id: 2,
            label: this.$t('courts.roofTypes.season'),
            value: 2
          },
          {
            id: 3,
            label: this.$t('courts.roofTypes.without'),
            value: 3
          }
        ],
        court: {
          name: {
            value: '',
            isValid: false
          },
          type: {
            label: '-',
            id: 2
          },
          roof: {
            label: '-',
            value: -1
          },
          logo: {}
        },
        roles: []
      }
    },
    methods: {
      checkUserPermissions(permission) {
        return checkUserPermissions(permission)
      },
      sendData() {
        if (!this.court.name.isValid) {
          this.$vs.notify({
            title: this.$t('alert.error.title'),
            text: this.$t('courts.validators.name'),
            icon: 'icon-alert-circle',
            iconPack: 'feather',
            time: 2400,
            color: 'danger'
          })
          return false
        } else if (this.court.type.id === -1) {
          this.$vs.notify({
            title: this.$t('alert.error.title'),
            text: this.$t('courts.validators.type'),
            icon: 'icon-alert-circle',
            iconPack: 'feather',
            time: 2400,
            color: 'danger'
          })
          return false
        }

        const court = {
          name: this.court.name.value,
          type: parseInt(this.court.type.id) > -1 ? this.court.type.id : null,
          avatar: this.court.logo.value ? this.court.logo.value : null
        }

        if (this.court.role && this.court.role.value > 0 && this.courtId !== this.$store.state.auth.court.id) court.role_id = this.court.role.value

        insertCourt(court).then(() => {
          this.$vs.notify({
            title: this.$t('alert.message.title'),
            text: this.$t('courts.notifications.insert.success'),
            icon: 'icon-check',
            iconPack: 'feather',
            time: 2400,
            color: 'success'
          })
          this.$emit('insert')
        }).catch((error) => {
          if (axios.isCancel(error)) {
            this.$vs.notify({
              title: this.$t('alert.duplicateRequest.title'),
              text: this.$t('alert.duplicateRequest.message'),
              icon: 'icon-alert-circle',
              iconPack: 'feather',
              time: 2400,
              color: 'warning'
            })
          } else {
            const error_mapper = {
              'name': this.$t('courts.notifications.parseError.name'),
              'type': this.$t('courts.notifications.parseError.type'),
              'avatar': this.$t('courts.notifications.parseError.image'),
            }

            switch (error.response.status) {
              case 422:
                Object.keys(error.response.data.errors).forEach((error_key) => {
                  const err = error_key.toString().split('.')
                  if (error_mapper[err[err.length - 1]]) {
                    this.$vs.notify({
                      title: this.$t('alert.error.title'),
                      text: error_mapper[err[err.length - 1]],
                      color: 'danger',
                      icon: 'icon-alert-circle',
                      iconPack: 'feather',
                      time: 2400
                    })
                    error_mapper[err[err.length - 1]] = null
                  }
                })
                break

              default:
                this.$vs.notify({
                  title: this.$t('alert.error.title'),
                  text: this.$t('courts.notifications.insert.error'),
                  color: 'danger',
                  icon: 'icon-alert-circle',
                  iconPack: 'feather',
                  time: 2400
                })
                break
            }
          }
        })
      }
    }
  }
</script>

<style lang="scss">
  .useral-insert-court {
    width: 100%;
    /*height: calc(100% - 10px);*/
    display: flex;

    @media (max-width: 767px) {
      display: block;
    }

    .menu-side {
      width: 200px;
      min-width: 200px;
      height: 100%;
      padding: 0 10px 0 0;

      @media (max-width: 767px) {
        width: 100%;
        min-width: 100%;
        padding: 0;
        height: fit-content;
      }

      ul {

        li {
          position: relative;
          border: 1px solid #cecece;
          border-radius: .5rem;
          margin-bottom: 10px;
          padding: 0 10px;
          line-height: 40px;
          cursor: pointer;
          transition: all 0.3s ease;

          .icon-chevron-left {
            position: absolute;
            height: fit-content;
            top: 0;
            bottom: 0;
            right: 6px;
            margin: auto;
          }

          &:hover {
            transform: translateX(5px);
          }
        }
      }
    }

    .view-side {
      flex-grow: 1;
      height: 100%;
      border: 1px solid #cecece;
      padding: 5px;
      border-radius: .5rem;
      overflow-y: auto;


      @media (max-width: 767px) {
        width: 100%;
        min-width: 100%;
        height: calc(100% - 230px);
      }

      .view-box {
        /*min-height: 300px;*/
        width: 100%;
        height: 100%;
        padding: 5px;
        overflow: auto;

        .logo-input {
          min-height: 100px;
        }

        .custom-profile-image-input {
          label {
            height: 110px;
            width: 110px;
          }
        }

        .date-picker-item {
          border: 0 !important;
          border-radius: 0.4rem;
          position: relative;

          .date-picker-label {
            font-size: 12px;
            position: absolute;
            top: -10px;
            left: 5px;
            padding: 0 5px;
            background-color: #ffffff;
            z-index: 1;
          }

          .vpd-input-group {
            width: 20px !important;
            position: absolute;
            top: 6px;
            left: 2px;

            .vpd-icon-btn {
              opacity: 1 !important;
              height: 25px !important;
              width: 20px !important;
              background-color: inherit !important;

              svg {
                fill: #000000;
                overflow: visible !important;
              }
            }

            .form-control {
              border: 0;
              width: 100%;
              display: none;
              line-height: 35px;
            }
          }
        }

        .custom-input:last-child {
          margin-bottom: 0;
        }

        .input-field-item {
          position: relative;
          border: 1px solid #cdcdcd;
          border-radius: 0.4rem;
          padding: 5px 10px;
          margin: 15px 0;
          display: flex;
          justify-content: space-around;
          min-height: 35px;

          &.invalid {
            border-color: #b00000;

            .input-field-label {
              color: #b00000;
            }
          }

          .input-field-label {
            position: absolute;
            font-size: 12px;
            top: -10px;
            left: 8px;
            background-color: #ffffff;
            padding: 0 5px;
          }

          .radio-item {
            display: flex;
            align-items: center;
            direction: rtl;

            label {
              margin-left: 5px;
            }
          }
        }

        div.edit-user-field {
          position: relative;

          span.label {
            font-size: 12px;
            position: absolute;
            top: -10px;
            left: 7px;
            z-index: 10000;
            background: #ffffff;
            padding: 0 5px;
          }

          .useral-custom-element-select {
            margin: 15px 0;

            .selected {
              height: 35px;
              line-height: 35px;
              font-size: 13px;
            }

            .items {
              line-height: 35px;

              div {
                height: 35px;
                font-size: 13px;
              }
            }
          }
        }

        .error-alert {
          display: inline-block;
          border: 1px solid #ffba00;
          border-radius: .5rem;
          color: #ffba00;
          padding: 0 10px;
          line-height: 50px;
          height: 50px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          font-size: 15px;

          i {
            font-size: 15px;
          }
        }

        &::-webkit-scrollbar {
          display: block;
          border-radius: .2rem;
          width: 10px;
          background: #f9f9fd;
          right: 10px;
        }

        &::-webkit-scrollbar-thumb {
          display: block;
          border-radius: .2rem;
          background: #cecece;
        }
      }
    }
  }
</style>
